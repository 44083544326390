














import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import AuthStore from '@/store/AuthStore'
import LatLng from '@/types/LatLng'
import DeviceMarker from '@/components/common/DeviceMarker.vue'

@Component<OwnerCurrentMap>({
  components: { DeviceMarker }
})
export default class OwnerCurrentMap extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.DocumentSnapshot<Device>

  get phoneNumber (): string {
    if (!AuthStore.firebaseUser) return ''
    return AuthStore.firebaseUser.phoneNumber || ''
  }

  get item (): Device | null {
    if (!this.doc) return null
    return this.doc.data() || null
  }

  get marker (): LatLng | null {
    if (!this.item) return null
    return { lat: this.item.lastSecond.latitude, lng: this.item.lastSecond.longitude }
  }

  get style (): string {
    let offset = 280
    if (this.$vuetify.breakpoint.xs) offset -= 74
    const height = this.$vuetify.breakpoint.height - offset
    return `height: ${height}px`
  }
}
