





























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { DriveScore } from 'functions/src/types/vehicle/drive-score'

@Component
export default class DisplayDriveScore extends Vue {
  @Prop({ type: Object, required: true }) item!: DriveScore
  @Prop({ type: Number, required: true }) distance!: number

  get point (): string {
    let summaryPoint = 0
    let constantValue = 0
    for (const [key, value] of Object.entries(this.item)) {
      if (value === 0) continue
      switch (key) {
        case 'overSpeed':
        case 'longOverSpeed':
          constantValue = 0.083
          break
        case 'fastAcceleration':
          constantValue = 0.18
          break
        case 'fastDeceleration':
          constantValue = 0.264
          break
        case 'fastRouteChange':
          constantValue = 0.245
          break
        case 'fastTurn':
          constantValue = 0.228
          break
        default:
          break
      }
      summaryPoint += 100 * value / this.distance * constantValue
    }
    return (100 - summaryPoint).toFixed(1)
    // return 100 - ((this.item.overSpeed || 0) + (this.item.longOverSpeed || 0) + (this.item.fastAcceleration || 0) + (this.item.fastDeceleration || 0) + (this.item.fastRouteChange || 0) + (this.item.fastTurn || 0)) / denominator
  }
}
