




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Trip } from '@/models/trip'
import moment from '@/plugins/moment'
import TimeDuration from '@/components/common/TimeDuration.vue'
import SecondsDialog from '@/components/common/SecondsDialog.vue'
import DisplayDriveScore from '@/components/common/DisplayDriveScore.vue'
import TripFromArrived from '@/components/common/TripFromArrived.vue'
import LatLng from '@/types/LatLng'
import AuthStore from '@/store/AuthStore'

@Component({ components: { TimeDuration, SecondsDialog, DisplayDriveScore, TripFromArrived } })
export default class TripItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Trip>
  @Prop({ type: Number, required: true, default: 0 }) index!: number

  loading = false

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get item (): Trip {
    return this.doc.data()
  }

  get firstTime (): moment.Moment {
    return moment(this.item.firstSecond.time)
  }

  get fromPosition (): LatLng {
    return {
      lat: this.item.firstSecond.latitude,
      lng: this.item.firstSecond.longitude
    }
  }

  get arrivedPosition (): LatLng {
    return {
      lat: this.item.lastSecond.latitude,
      lng: this.item.lastSecond.longitude
    }
  }

  get lastTime (): moment.Moment {
    return moment(this.item.lastSecond.time)
  }

  get rangetime (): string {
    const val = `${this.firstTime.format('a hh:mm:ss')} ~ ${this.lastTime.format('a hh:mm:ss')}`
    return val
  }

  get seconds (): number {
    return this.lastTime.diff(this.firstTime, 'seconds')
  }

  get values (): string {
    const distance = this.item.lastSecond.trip.toFixed(2) + 'Km'
    const fuel = this.item.lastSecond.fuel.toFixed(2) + 'L'

    return [distance, fuel].join(' | ')
  }

  get isAdmin (): boolean {
    return AuthStore.isAdmin
  }

  get dataURL (): string {
    if (process.env.VUE_APP_FIREBASE_DATA === 'emulator') return 'http://localhost:7771/mobile-dtg/us-central1/api/'
    return 'https://us-central1-mobile-dtg.cloudfunctions.net/api/'
  }

  async update (): Promise<void> {
    if (!AuthStore.firebaseUser) return
    const r = confirm('진짜 할꺼?')
    if (!r) return
    try {
      this.loading = true

      const idToken = await AuthStore.firebaseUser.getIdToken()
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${idToken}`
        }
      }
      const res = await fetch(this.dataURL + `admin/calculate-trip/${this.doc.id}`, options)

      const text = await res.text()
      alert(text)
    } finally {
      this.loading = false
    }
  }

  remove (): void {
    this.$emit('remove', this.doc)
  }
}
