











import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import DayItem from '@/components/common/DayItem.vue'
import dayCollection, { Day } from '@/models/day'

@Component<OwnerCurrentDay>({
  components: { DayItem, OwnerCurrentDay },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }

})
export default class OwnerCurrentDay extends Vue {
  @Prop({ type: Object, required: true }) deviceDoc!: firebase.firestore.DocumentSnapshot<Device>
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Day>[] = []

  get device (): Device | null {
    if (!this.deviceDoc) return null
    return this.deviceDoc.data() || null
  }

  get doc (): firebase.firestore.QueryDocumentSnapshot<Day> | null {
    if (!this.docs.length) return null
    return this.docs[0]
  }

  subscribe (): void {
    this.unsubscribe = dayCollection.where('device', '==', this.deviceDoc.ref).orderBy('firstSecond.time', 'desc').limit(1).onSnapshot(sn => {
      if (sn.empty) return

      this.docs = sn.docs
    })
  }
}
