


































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import DisplayPhoneNumber from '@/components/common/DisplayPhoneNumber.vue'
import DeviceInfo from '@/components/common/DeviceInfo.vue'

@Component({ components: { DisplayPhoneNumber, DeviceInfo } })
export default class DeviceChip extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.DocumentSnapshot<Device>
  menu = false

  get item (): Device | null {
    return this.doc.data() || null
  }
}
