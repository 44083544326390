
















import { Component, Vue } from 'vue-property-decorator'
import OwnerCurrentLayout from '@/components/owner/OwnerCurrentLayout.vue'

  @Component({ components: { OwnerCurrentLayout } })
export default class OwnerCurrentIndex extends Vue {

}
