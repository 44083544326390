


























import { Component, Vue } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import deviceCollection, { Device } from '@/models/device'
import AuthStore from '@/store/AuthStore'
import OwnerCurrentMap from '@/components/owner/OwnerCurrentMap.vue'
import OwnerCurrentDay from '@/components/owner/OwnerCurrentDay.vue'

@Component<OwnerCurrentLayout>({
  components: { OwnerCurrentMap, OwnerCurrentDay },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class OwnerCurrentLayout extends Vue {
  unsubscribe: firebase.Unsubscribe | null = null
  doc: firebase.firestore.DocumentSnapshot<Device> | null = null

  get phoneNumber (): string {
    if (!AuthStore.firebaseUser) return ''
    return AuthStore.firebaseUser.phoneNumber || ''
  }

  subscribe (): void {
    if (!this.phoneNumber) return
    const ref = deviceCollection.doc(this.phoneNumber)
    this.unsubscribe = ref.onSnapshot(doc => {
      if (!doc.exists) return
      this.doc = doc
    })
  }
}
