




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import DisplayPhoneNumber from '@/components/common/DisplayPhoneNumber.vue'
import LatLng from '@/types/LatLng'
import { Device } from '@/models/device'
import { VehicleSecond } from 'functions/src/types/vehicle/second'
import VehicleInfo from 'functions/src/types/vehicle/info'
import moment from '@/plugins/moment'
import DeviceInfo from '@/components/common/DeviceInfo.vue'

@Component({ components: { GmapCustomMarker, DisplayPhoneNumber, DeviceInfo } })
export default class DeviceMarker extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Device>
  menu = false

  get item (): Device {
    return this.doc.data()
  }

  get info (): VehicleInfo {
    return this.item.info
  }

  get second (): VehicleSecond {
    return this.item.lastSecond
  }

  get marker (): LatLng {
    return { lat: this.second.latitude, lng: this.second.longitude }
  }

  get color (): string {
    const diff = moment().diff(this.second.time, 'seconds')
    if (diff < 600) return 'success'
    return 'error'
  }

  get icon (): string {
    const azimuth = this.item.lastSecond.azimuth
    let aridx = Number((azimuth + 22.5) / 45)
    if (aridx > 7) aridx = 0
    return `mdi-arrow-up-bold-circle-outline mdi-rotate-${aridx * 45}`
  }

  get carIcon (): string {
    const icons: { [key: number]: string } = {
      11: 'mdi-dump-truck',
      12: 'mdi-bus'
    }
    const icon = icons[this.info.vehicleTypeIndex]
    if (!icon) return 'mdi-car'
    return icon
  }
}
