























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Day } from '@/models/day'
import tripCollection, { Trip } from '@/models/trip'
import TripItem from '@/components/common/TripItem.vue'
import moment from '@/plugins/moment'

@Component<TripList>({
  components: { TripItem }
})
export default class TripList extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Day>
  @Prop({ type: Boolean, required: true }) active!: boolean
  docs: firebase.firestore.DocumentSnapshot<Trip>[] = []
  limit = 10
  empty = false
  loading = false

  get day (): Day {
    return this.doc.data()
  }

  get last (): firebase.firestore.DocumentSnapshot<Trip> | null {
    if (!this.docs.length) return null
    return this.docs[this.docs.length - 1]
  }

  @Watch('active')
  onChangeActive (active: boolean): void {
    if (!active) return
    this.fetch()
  }

  async fetch (): Promise<void> {
    console.log('fetch')
    try {
      this.loading = true
      const ref = tripCollection
        .where('device', '==', this.day.device)
        .where('company', '==', this.day.company)
        .where('group', '==', this.day.group)
        .where('firstSecond.time', '>=', this.day.firstSecond.time)
        .where('firstSecond.time', '<=', moment(this.day.firstSecond.time).endOf('days').toISOString())
        .orderBy('firstSecond.time', 'asc')

      const query = this.last ? ref.startAfter(this.last) : ref
      const sn = await query.limit(this.limit).get()
      this.empty = sn.empty
      if (sn.empty) return
      this.docs = this.docs.concat(sn.docs)
    } finally {
      this.loading = false
    }
  }

  onIntersect (entries: unknown, observer: unknown, isIntersecting: boolean): void {
    if (!this.docs.length) return
    if (isIntersecting) this.fetch()
  }

  remove (selectedDoc: firebase.firestore.QueryDocumentSnapshot<Trip>): void {
    console.log(selectedDoc.data())
    const index = this.docs.findIndex(doc => doc.id === selectedDoc.id)
    if (index < 0) return
    selectedDoc.ref.delete()
    this.docs.splice(index, 1)
  }
}
