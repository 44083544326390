










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Client, LatLngLiteral } from '@googlemaps/google-maps-services-js'
import axios from 'axios'

const client = new Client()

@Component<TripFromArrived>({
  async created () {
    this.loading = true

    try {
      const p1 = this.fetchAddress(this.from.lat, this.from.lng).then((res) => {
        this.fromAddress = res.data.results[0].formatted_address
      })
        .catch((err) => {
          console.log(err)
          this.fromAddress = '알수없음'
        })
      const p2 = this.fetchAddress(this.arrived.lat, this.arrived.lng).then((res) => {
        this.arrivedAddress = res.data.results[0].formatted_address
      })
        .catch((err) => {
          console.log(err)
          this.arrivedAddress = '알수없음'
        })

      await Promise.all([p1, p2])
    } catch (err) {
      console.log(err)
    }

    this.loading = false
  }
})
export default class TripFromArrived extends Vue {
  @Prop({ required: true }) from!: LatLngLiteral
  @Prop({ required: true }) arrived!: LatLngLiteral

  loading = false
  fromAddress: string | null = null
  arrivedAddress: string | null = null

  async fetchAddress (lat: number, lng: number) {
    // const params = {
    //   latlng: {
    //     lat, lng
    //   },
    //   key: process.env.VUE_APP_GOOGLE_MAP_KEY
    // }

    // console.log(params)

    // const res = await client.reverseGeocode({
    //   params: params
    // })

    const res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        latlng: [lat, lng].join(','),
        key: 'AIzaSyBSNxqCl5Fcbe9ehF9KIDj5ypNQQuh6DF4'
      }
    })

    console.log(res)

    return res
  }
}
