
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Day } from '@/models/day'
import DisplayTime from '@/components/common/DisplayTime.vue'
import moment from '@/plugins/moment'
import TripList from '@/components/common/TripList.vue'
import { Device } from '@/models/device'
import DeviceChip from '@/components/common/DeviceChip.vue'
import DisplayDriveScore from '@/components/common/DisplayDriveScore.vue'
import TimeDuration from '@/components/common/TimeDuration.vue'

@Component({
  components: {
    DisplayTime, TripList, DeviceChip, DisplayDriveScore, TimeDuration
  }
})
export default class DayItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Day>
  @Prop({ type: Array, default: () => [] }) deviceDocs!: firebase.firestore.DocumentSnapshot<Device>[]
  active = false

  get item (): Day {
    return this.doc.data()
  }

  get date (): string {
    return this.firstTime.format('YYYY-MM-DD (ddd)')
  }

  get firstTime (): moment.Moment {
    return moment(this.item.firstSecond.time)
  }

  get lastTime (): moment.Moment {
    return moment(this.item.lastSecond.time)
  }

  get rangetime (): string {
    const val = `${this.firstTime.format('a hh:mm:ss')} ~ ${this.lastTime.format('a hh:mm:ss')}`
    return val
  }

  get seconds (): number {
    return this.lastTime.diff(this.firstTime, 'seconds')
  }

  get deviceDoc (): firebase.firestore.DocumentSnapshot<Device> | null {
    return this.deviceDocs.find(item => item.id === this.item.device.id) || null
  }

  get device (): Device | null {
    if (!this.deviceDoc) return null
    return this.deviceDoc.data() || null
  }

  get values (): string {
    const distance = this.item.lastSecond.dayTrip.toFixed(2) + 'Km'
    const fuel = this.item.lastSecond.dayFuel.toFixed(2) + 'L'
    const count = this.item.tripCount + '회 주행'

    return [distance, fuel, count].join(' | ')
  }
}
